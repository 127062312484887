import React, { useState } from 'react';
import MaskedInput from 'antd-mask-input';

import Footer from '@components/Shared/Footer/Footer';
import Footer2 from '@components/Shared/Fotter2/Footer2';
import Modal from '@src/components/Shared/ModalPure';
import Termo from '@src/pages/Termo';

import { NavigateNextOutlined, ReportGmailerrorred, CircleNotifications } from '@mui/icons-material/';

import { Grid } from '@material-ui/core';
import { Form, Input, Spin, Radio, Select as SelectAntd, Switch, Checkbox } from 'antd';

import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import ButtonComponent from '@components/Shared/Buttons/Button';

import { TitlePage } from '@styles/styles.global';
import InvisibleContent from '@components/Shared/InvisibleContent';

import '@styles/AntStyles.css';
import { addDays } from 'date-fns';

import Cartao from '@assets/Login/cartao.png'
import Chip from '@assets/Login/chip.png'
import Energia from '@assets/Login/coelba.png'


import {
  Container
} from '@src/components/Shared/Card/CardStyle';

import Service, {
  IRequestCreatePessoa, IRequestCreatePessoaCredito, IRequestCreatePessoaDebito, IRequestCreatePessoaEnergia
} from './services';
import { MaskCPFeCNPJ } from '@utils/Masks';
import { validaCPFandCNPJ } from '@utils/Validators';
import SearchCEPCorreios from '@utils/SearchCEPCorreios';
import { isReal, ClearString } from '@utils/Masks';


import useDebounce from '@hooks/useDebounce';

const DATA = addDays(new Date(), 0).toISOString().split('T')[0];

interface IConvenio {
  id: number;
  descricao: string;
}

interface IOperacaoCaixa {
  id: number;
  descricao: string;
  codigo_operacao: string;
}

interface IOperacaoSantander {
  id: number;
  descricao: string;
  codigo_operacao: string;
}

interface IRecorrencia {
  id: number;
  descricao: string;
}

interface IData {
  id: string;
}

const Principal: React.FC = () => {

  const [formPessoaRef] = Form.useForm();
  const [formRef] = Form.useForm();

  const [loadingCreatePessoaCredito, setLoadingCreatePessoaCredito] = useState(false);
  const [loadingCreatePessoaPix, setLoadingCreatePessoaPix] = useState(false);
  const [loadingCreatePessoaBoleto, setLoadingCreatePessoaBoleto] = useState(false);
  const [loadingCreatePessoaDebito, setLoadingCreatePessoaDebito] = useState(false);
  const [loadingCreatePessoaEnergia, setLoadingCreatePessoaEnergia] = useState(false);
  const [loadingCreatePessoaTeste, setLoadingCreatePessoaTeste] = useState(false);


  const [loading, setLoading] = useState(false);
  const [valorDoacao, setValorDoacao] = useState(false);

  const [formaContribPix, setFormaContribPix] = useState(false);
  const [formaContribCredito, setFormaContribCredito] = useState(false);
  const [formaContribBoleto, setFormaContribBoleto] = useState(false);
  const [formaContribDebito, setFormaContribDebito] = useState(false);
  const [formaContribEnergia, setFormaContribEnergia] = useState(false);

  const [fraseDoacao0, setFraseDoacao0] = useState(false);
  const [fraseDoacao1, setFraseDoacao1] = useState(false);
  const [fraseDoacao2, setFraseDoacao2] = useState(false);
  const [fraseDoacao3, setFraseDoacao3] = useState(false);
  const [fraseDoacao4, setFraseDoacao4] = useState(false);


  const [fraseDoacao5, setFraseDoacao5] = useState(false);
  const [fraseDoacao6, setFraseDoacao6] = useState(false);
  const [fraseDoacao7, setFraseDoacao7] = useState(false);
  const [fraseDoacao8, setFraseDoacao8] = useState(false);
  const [fraseDoacao9, setFraseDoacao9] = useState(false);

  const [confirmacao, setConfirmacao] = useState(false);


  const [tipoDocumento, setTipoDocumento] = useState<'pf' | 'pj'>('pf');

  const [valorModifyValorDebito, setValorModifyValorDebito] =
  useState<number>(0);


  const [stateConfirme, setStateConfirme] = useState({
    number: '',

  });
  const [loadingCEP, setLoadingCEP] = useState(false);
  const [uf, setUF] = useState<any>();
  const [checked, setChecked] = useState(false);
  const [checkedTermo1, setCheckedTermo1] = useState(false);
  const [checkedTermo2, setCheckedTermo2] = useState(false);

  const [convenioSelecionada, setConvenioSelecionada] =
  useState('');
  const [etapa, setEtapa] = useState(0);


  const [convenio, setConvenio] = useState<IConvenio[]>([
    { id: 5, descricao: 'BANCO DO BRASIL' },
    { id: 7, descricao: 'BANCO SANTANDER S.A.' },
    { id: 9, descricao: 'BANCO BRADESCO S.A.' },
    { id: 22, descricao: 'CAIXA ECONOMICA FEDERAL' },
    { id: 23, descricao: 'BANCO ITAÚ S.A.' },
  ]);


  const [operacaoCaixa, setOperacaoCaixa] = useState<IOperacaoCaixa[]>([
    { id: 1, descricao: 'Cta. Corrente - P.Física', codigo_operacao: '001' },
    { id: 2, descricao: 'Cta. Simples - P.Física', codigo_operacao: '002' },
    { id: 3, descricao: 'Cta. Corrente - P.Jurídica', codigo_operacao: '003' },
    { id: 4, descricao: 'Entidades Públicas', codigo_operacao: '006' },
    { id: 5, descricao: 'Dep. Inst. Financeiras', codigo_operacao: '007' },
    { id: 6, descricao: 'Consignação em pagamento', codigo_operacao: '011' },
    { id: 7, descricao: 'Poupança - P.Física', codigo_operacao: '013' },
    { id: 8, descricao: 'Conta Benefício', codigo_operacao: '018' },
    { id: 9, descricao: 'Poupança - P.Jurídica', codigo_operacao: '022' },
    { id: 10, descricao: 'Conta Eletrônica', codigo_operacao: '023' },
    { id: 11, descricao: 'Poupança-Créd Imobiliário', codigo_operacao: '028' },
    { id: 12, descricao: 'Dep. Lotéricos', codigo_operacao: '043' },
  ]);

  const [operacaoSantander, setOperacaoSantamder] = useState<IOperacaoSantander[]>([
    { id: 13, descricao: 'Operação 001', codigo_operacao: '001' },
    { id: 14, descricao: 'Operação 002', codigo_operacao: '002' },
    { id: 15, descricao: 'Operação 003', codigo_operacao: '003' },
    { id: 16, descricao: 'Operação 005', codigo_operacao: '005' },
    { id: 17, descricao: 'Conta salário', codigo_operacao: '060' },
    { id: 18, descricao: 'Poupança', codigo_operacao: '071' },
    { id: 19, descricao: 'Cta. Corrente - P.Física', codigo_operacao: '092' },
  ]);

  const [recorrencias, setRecorrencias] = useState<IRecorrencia[]>([
    { id: 1, descricao: 'Mensal' },
    { id: 2, descricao: 'Bimestral' },
    { id: 3, descricao: 'Trimestral' },
    { id: 6, descricao: 'Semestral' },
    { id: 12, descricao: 'Anual' },
  ]);

  const [data, setDatas] = useState<IData[]>([
    { id: '1' },  { id: '2' }, { id: '3' },{ id: '4' },{id: '5' },
    {id: '6' }, {id: '7' }, {id: '8' }, {id: '9' }, {id: '10' },
    {id: '11' }, {id: '12' }, {id: '13' }, {id: '14' }, {id: '15' },
    {id: '16' }, {id: '17' }, {id: '18' }, {id: '19' }, {id: '20' },
    {id: '21' }, {id: '22' }, {id: '23' }, {id: '24' }, {id: '25' },
    {id: '26' }, {id: '27' }, {id: '28' }, {id: '29' }, {id: '29' },
    {id: '30' }, {id: '31' },
  ]);

  const [openModalTermo, setOpenModalTermo] =
  useState(false);


  const filterCheckTipoConvenio = (descricao: any) => {
    const forma = convenio.filter(x => x.descricao === descricao);

    if (forma.length > 0) setConvenioSelecionada(forma[0].descricao);
  };

  const HandleChangeEtapa = (value: number) => {
    setEtapa(value);

  };


  console.log('jflksdjf',etapa)

  function handleInput(e: any) {
    var ss = e.target.selectionStart;
    var se = e.target.selectionEnd;
    e.target.value = e.target.value.toUpperCase();
    e.target.selectionStart = ss;
    e.target.selectionEnd = se;
  };

  const createArrayRange = (
    start: number,
    increment: number,
    length: number,
  ) => {
    let array = [];

    for (let i = start; i <= length; i += increment) {
      array.push(i);
    }

    return array;
  };

  async function onSubmitTeste(values: any) {

    setLoadingCreatePessoaTeste(true);
    setLoading(true);
    //console.log(values);

    const services = new Service();
    const createPessoaCredito: IRequestCreatePessoaCredito = {
      pessoa: {
        nome: values.nome,
        cpfcnpj: values.cpfcnpj,
        email: values.email,
        telefone: values.telefone,
        data_nascimento: values.data_nascimento,
        indicador: values.indicador,
        leu_comunicado_privacidade: values.leu_comunicado_privacidade === undefined
        ?false
        :true,
        concede_compartilhamento_dados: values.concede_compartilhamento_dados === undefined
        ?values.concede_compartilhamento_dados
        :values.concede_compartilhamento_dados
        ?true
        :false,
      },
      doacao: {
        valor: (valorModifyValorDebito) / 100 * 100,

        cartao: values.cartao === undefined
        ?false
        :true,
        boleto: values.boleto === false,
        pix: values.pix  === false,
        recorrente: values.recorrente  === undefined
          ?false
          :true,
      },
      endereco: {
        descricao: values.descricao,
        numero: values.numero,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        cep: values.cep,
        uf: values.uf,
      },
      entidade: {
        nome: 'osid'
      },
      campanha:{
        descricao: 'teste2',
      },
      cartao: {
        number: values.number,
        verification_value: values.verification_value,
        first_name: values.first_name,
        last_name: values.last_name,
        month: values.month,
        year: values.year,
      }
    };

    //console.log('entrou aqui')


    setLoadingCreatePessoaTeste(false);

    //window.location.reload();

    console.log('efjdlkf0', loadingCreatePessoaTeste);
  }

  async function onSubmitCredito(values: any) {

    setLoadingCreatePessoaCredito(true);
    setLoading(true);
    //console.log(values);

    const services = new Service();
    const createPessoaCredito: IRequestCreatePessoaCredito = {
      pessoa: {
        nome: values.nome,
        cpfcnpj: values.cpfcnpj,
        email: values.email,
        telefone: values.telefone,
        data_nascimento: values.data_nascimento,
        indicador: values.indicador,
        leu_comunicado_privacidade: values.leu_comunicado_privacidade === undefined
        ?false
        :true,
        concede_compartilhamento_dados: values.concede_compartilhamento_dados === undefined
        ?values.concede_compartilhamento_dados
        :values.concede_compartilhamento_dados
        ?true
        :false,
      },
      doacao: {
        valor: (valorModifyValorDebito) / 100 * 100,

        cartao: values.cartao === undefined
        ?false
        :true,
        boleto: values.boleto === false,
        pix: values.pix  === false,
        recorrente: values.recorrente  === undefined
          ?false
          :true,
      },
      endereco: {
        descricao: values.descricao,
        numero: values.numero,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        cep: values.cep,
        uf: values.uf,
      },
      entidade: {
        nome: 'osid'
      },
      campanha:{
        descricao: 'teste2',
      },
      cartao: {
        number: values.number,
        verification_value: values.verification_value,
        first_name: values.first_name,
        last_name: values.last_name,
        month: values.month,
        year: values.year,
      }
    };

    //console.log('entrou aqui')

    const { error, response } = await services.CreatePessoaCredito(createPessoaCredito);

    if (!error) {
      window.alert("Cadastrado com sucesso! A OSID agradece sua doação!!");

    } else {
      window.alert( "Erro - Sem contato com a base de dados. Por favor tente novamente ou entre em contato com a central de relacionamento!");

    }

    setLoadingCreatePessoaCredito(false);

    //window.location.reload();
  }

  const onChange = (e: CheckboxChangeEvent) => {
    console.log('checked = ', e.target.checked);
    setChecked(e.target.checked);

  };

  async function onSubmitPix(values: any) {

    setLoadingCreatePessoaPix(true);
    setLoading(true);
    //console.log(values);

    const services = new Service();
    const createPessoaPix: IRequestCreatePessoa = {
      pessoa: {
        nome: values.nome,
        cpfcnpj: values.cpfcnpj,
        email: values.email,
        telefone: values.telefone,
        data_nascimento: values.data_nascimento,
        indicador: values.indicador,
        leu_comunicado_privacidade: values.leu_comunicado_privacidade === undefined
        ?false
        :true,
        concede_compartilhamento_dados: values.concede_compartilhamento_dados === undefined
        ?values.concede_compartilhamento_dados
        :values.concede_compartilhamento_dados
        ?true
        :false,
      },
      doacao: {
        valor: (valorModifyValorDebito) / 100 * 100,

        cartao: values.cartao === false,
        boleto: values.boleto === false,
        pix: values.pix === undefined
        ?false
        :true,
        recorrente: values.recorrente  === undefined
          ?false
          :true,
      },
      endereco: {
        descricao: values.descricao,
        numero: values.numero,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        cep: values.cep,
        uf: values.uf,
      },
      entidade: {
        nome: 'osid'
      },
      campanha:{
        descricao: 'teste2',
      }
    };

    //console.log('entrou aqui')

    const { error, response } = await services.CreatePessoa(createPessoaPix);

    if (!error) {
      window.open(response);

    } else {
      window.alert( "Erro - Sem contato com a base de dados. Por favor tente novamente ou entre em contato com a central de relacionamento!");

    }

    setLoadingCreatePessoaPix(false);

    //window.location.reload();
  }


  async function onSubmitBoleto(values: any){

    setLoadingCreatePessoaBoleto(true);
    setLoading(true);
    //console.log(values);

    const services = new Service();
    const createPessoaBoleto: IRequestCreatePessoa = {
      pessoa: {
        nome: values.nome,
        cpfcnpj: values.cpfcnpj,
        email: values.email,
        telefone: values.telefone,
        data_nascimento: values.data_nascimento,
        indicador: values.indicador,
        leu_comunicado_privacidade: values.leu_comunicado_privacidade === undefined
        ?false
        :true,
        concede_compartilhamento_dados: values.concede_compartilhamento_dados === undefined
        ?values.concede_compartilhamento_dados
        :values.concede_compartilhamento_dados
        ?true
        :false,
      },
      doacao: {
        valor: (valorModifyValorDebito) / 100 * 100,

        cartao: values.cartao === false,
        boleto: values.boleto === undefined
        ?false
        :true,
        pix: values.pix === false,
        recorrente: values.recorrente  === undefined
          ?false
          :true,
      },
      endereco: {
        descricao: values.descricao,
        numero: values.numero,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        cep: values.cep,
        uf: values.uf,
      },
      entidade: {
        nome: 'osid'
      },
      campanha:{
        descricao: 'teste2',
      }
    };

    //console.log('entrou aqui')

    const { error, response } = await services.CreatePessoa(createPessoaBoleto);

    if (!error) {
      window.open(response);

    } else {
      window.alert( "Erro - Sem contato com a base de dados. Por favor tente novamente ou entre em contato com a central de relacionamento!");

    }

    setLoadingCreatePessoaBoleto(false);

    //window.location.reload();
  }

  async function onSubmitDebito(values: any) {

    setLoadingCreatePessoaDebito(true);
    setLoading(true);
    //console.log(values);

    const services = new Service();
    const CreatePessoaDebito: IRequestCreatePessoaDebito = {
      pessoa: {
        nome: values.nome,
        cpfcnpj: values.cpfcnpj,
        email: values.email,
        telefone: values.telefone,
        data_nascimento: values.data_nascimento,
        indicador: values.indicador,
        leu_comunicado_privacidade: values.leu_comunicado_privacidade === undefined
        ?false
        :true,
        concede_compartilhamento_dados: values.concede_compartilhamento_dados === undefined
        ?values.concede_compartilhamento_dados
        :values.concede_compartilhamento_dados
        ?true
        :false,
      },
      //doacao: {
        //valor: (valorModifyValorDebito) / 100 * 100,
      //},
      endereco: {
        descricao: values.descricao,
        numero: values.numero,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        cep: values.cep,
        uf: values.uf,
      },
      entidade: {
        nome: 'osid'
      },
      campanha:{
        descricao: 'teste2',
      },
      debito_automatico: {
        convenio: values.convenio,
        vencimento: values.vencimento,
        numero_agencia: values.numero_agencia,
        digito_agencia: values.digito_agencia,
        numero_conta: values.numero_conta,
        digito_conta: values.digito_conta,
        operacao: values.operacao,
        valor: (valorModifyValorDebito) / 100 * 100,
      }
    };

    //console.log('entrou aqui')

    const { error, response } = await services.CreatePessoaDebito(CreatePessoaDebito);

    if (!error) {
      window.alert("Cadastrado com sucesso! A OSID agradece sua doação!!");

    } else {
      window.alert( "Erro - Sem contato com a base de dados. Por favor tente novamente ou entre em contato com a central de relacionamento!");

    }

    setLoadingCreatePessoaDebito(false);

    //window.location.reload();
  }

  async function onSubmitEnergia(values: any) {

    setLoadingCreatePessoaEnergia(true);
    setLoading(true);
    //console.log(values);

    const services = new Service();
    const createPessoaEnergia: IRequestCreatePessoaEnergia = {
      pessoa: {
        nome: values.nome,
        cpfcnpj: values.cpfcnpj,
        email: values.email,
        telefone: values.telefone,
        data_nascimento: values.data_nascimento,
        indicador: values.indicador,
        leu_comunicado_privacidade: values.leu_comunicado_privacidade === undefined
        ?false
        :true,
        concede_compartilhamento_dados: values.concede_compartilhamento_dados === undefined
        ?values.concede_compartilhamento_dados
        :values.concede_compartilhamento_dados
        ?true
        :false,
      },
      //doacao: {
       // valor: (valorModifyValorDebito) / 100 * 100,
      //},
      endereco: {
        descricao: values.descricao,
        numero: values.numero,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        cep: values.cep,
        uf: values.uf,
      },
      entidade: {
        nome: 'osid'
      },
      campanha:{
        descricao: 'teste2',
      },
      debito_coelba: {
        numero_conta_energia: values.numero_conta_energia,
        recorrencia: values.recorrencia,
        valor: (valorModifyValorDebito) / 100 * 100,

      }
    };

    //console.log('entrou aqui')

    const { error, response } = await services.CreatePessoaEnergia(createPessoaEnergia);

    if (!error) {
      window.alert("Cadastrado com sucesso! A OSID agradece sua doação!!");

    } else {
      window.alert( "Erro - Sem contato com a base de dados. Por favor tente novamente ou entre em contato com a central de relacionamento!");

    }

    setLoadingCreatePessoaEnergia(false);

    //window.location.reload();
  }

  const searchCEPDebounce = async (value: string) => {
    const searchCEPCorreios = new SearchCEPCorreios();

    setLoadingCEP(true);
    const { error, response } = await searchCEPCorreios.SearchCEP(value);
    setLoadingCEP(false);
    if (!error && response) {
      setUF(response.uf);
      formPessoaRef.setFieldsValue({
        descricao: response.logradouro,
        bairro: response.bairro,
        uf: response.uf,
        cidade: response.localidade,
      });
    }
  };

  const debounceCEP = useDebounce(searchCEPDebounce, 800);

  const onChangeDocumento = (e: any) => {
    const value = e.target.value;

    const { typeDocument, valueModify } = MaskCPFeCNPJ(value);

    setTipoDocumento(typeDocument);

    formPessoaRef.setFieldsValue({
      cpfcnpj: valueModify,
    });

    if (typeDocument === 'pj') {
      formPessoaRef.setFieldsValue({
        sexo: 1,
      });
    }
  };

  const onChangeDocumento2 = (e: any) => {
    const value = e.target.value;

    const { typeDocument, valueModify } = MaskCPFeCNPJ(value);

    setTipoDocumento(typeDocument);

    formPessoaRef.setFieldsValue({
      indicador: valueModify,
    });

    if (typeDocument === 'pj') {
      formPessoaRef.setFieldsValue({
        sexo: 1,
      });
    }
  };

  const validaDocumento = (_: any, val: any) => {
    const documentoExiste = validaCPFandCNPJ(val);

    if (documentoExiste) {
      return Promise.resolve();
    }

    return Promise.reject(new Error('Digite um documento válido'));
  };

  return (
    <>
      <Grid container alignItems="center" justify="center">
        <Grid container item lg={12} sm={8} xs={12} style={{marginTop: 40, marginRight: 35}} justify="center" >

          <Grid item lg={3} sm={1} xs={12}> </Grid>

          <Grid item lg={2} sm={6} xs={12} justify="center">
            <img src={'https://www.irmadulce.org.br/storage/admin/uploads/images/principal/configuracaoGeral/logo-1-1663081896.svg'} style={{ }} />
          </Grid>

          <Grid item lg={6} sm={12} xs={12} style={{}} justify="center" >
            <TitlePage style={{fontSize: 27}}>Sim, quero ser Amigo(a)
              <p style={{fontSize: 28, color: '#3092cf'}}> de Santa Dulce</p>
            </TitlePage>
          </Grid>

        </Grid>
      </Grid>

      <Grid
        container
        item
        lg={12}
        sm={12}
        justify="space-around"
        xs={12}
        style={{ marginTop: 25 }}
      >
        {/* FORMULARIO  */}
        <Grid item lg={8} sm={9} xs={12}>
            <Form
              form={formPessoaRef}
              size="middle"
              layout="vertical"
              scrollToFirstError={true}
              //onFinish={onSubmitCredito}
              labelAlign="left"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              requiredMark={false}
              initialValues={{
                nome: '',
                cpfcnpj: '',
                email: '',
                descricao: '',
                numero: '',
                complemento: '',
                bairro: '',
                cidade: '',
                cep: '',
                uf: '',
              }}
            >
                <Grid container justify="center" >
                  <InvisibleContent
                    visible={tipoDocumento === 'pf'}
                  >
                    <Grid item lg={12} sm={12} xs={12}>
                      <Form.Item
                        label='Nome Completo *'

                        name="nome"
                        rules={[
                          { required: true, message: 'Campo obrigatório' },
                          { min: 3, message: 'Mínimo de 3 letras' },
                          {
                            pattern:  /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
                            message: 'Somente letras'
                          }
                        ]}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Input
                          onChange={() => setFraseDoacao0(true)} onInput={handleInput}

                        />
                      </Form.Item>
                    </Grid>
                  </InvisibleContent>

                  <InvisibleContent
                    visible={tipoDocumento === 'pj'}
                  >
                    <Grid item lg={12} sm={12} xs={12}>
                      <Form.Item
                        label='Razão Social *'
                        name="nome"
                        rules={[
                          { required: true, message: 'Campo obrigatorio' },
                          { min: 3, message: 'Minimo de 3 letras' },

                        ]}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Input
                          onChange={() => setFraseDoacao0(true)} onInput={handleInput}
                        />
                      </Form.Item>
                    </Grid>
                  </InvisibleContent>
                </Grid>

                <Grid container>
                  <Grid item lg={6} sm={6}  xs={12} style={{ paddingRight: 10, paddingLeft: 10 }}>
                    <Form.Item
                      label="CPF/CNPJ *"
                      name="cpfcnpj"
                      rules={[
                        { required: true, message: 'Campo obrigatório' },
                        { min: 14, message: 'Minimo 14 caracteres' },
                        { validator: (e, a) => validaDocumento(e, a) },
                      ]}
                    >
                      <Input
                        maxLength={18}
                        onChange={(e: any) => {
                          onChangeDocumento(e);
                          setFraseDoacao1(true);
                        }}
                        //placeholder="Documento"

                      />
                    </Form.Item>
                  </Grid>

                  <InvisibleContent
                    visible={tipoDocumento === 'pf'}
                  >
                    <Grid item lg={6} sm={6} xs={12}>
                      <Form.Item
                        label='Data de nascimento *'

                        name="data_nascimento"
                        rules={[
                          { required: true, message: 'Campo obrigatório' },
                        ]}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Input
                          type="date"
                          max={DATA}
                          onChange={() => setFraseDoacao2(true)}

                        />
                      </Form.Item>
                    </Grid>
                  </InvisibleContent>

                  <InvisibleContent
                    visible={tipoDocumento === 'pj'}
                  >
                    <Grid item lg={6} sm={6} xs={12}>
                      <Form.Item
                        label='Data de fundação*'
                        name="data_nascimento"
                        rules={[
                          { required: true, message: 'Campo obrigatorio' },

                        ]}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Input
                          type="date"
                          max={DATA}
                          onChange={() => setFraseDoacao2(true)}
                        />
                      </Form.Item>
                    </Grid>
                  </InvisibleContent>
                </Grid>
                  <Grid container>
                    <Grid item lg={6} sm={6} xs={12}>
                      <Form.Item
                        label='E-mail *'
                        name="email"
                        rules={[
                          { required: true, message: 'Campo obrigatório' },
                          { pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                            message: 'Por favor digite um e-mail válido!' },
                          ]}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Input  onChange={() => setFraseDoacao3(true)}/>
                        </Form.Item>
                    </Grid>

                    <Grid item lg={6} sm={6} xs={12}>
                      <Form.Item
                        label='Celular *'
                        name="telefone"
                        rules={[
                          { required: true, message: 'Campo obrigatório' },

                        ]}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                      >
                        <MaskedInput
                        onChange={() => setFraseDoacao4(true)}
                          mask="(11) 11111-1111"
                          //placeholder="Celular"
                        />
                      </Form.Item>
                    </Grid>
                  </Grid>


                  {/* ------------------------ DOAÇÃO -------------------------------------------------- */}
                  <strong><p style={{paddingTop: 25, fontSize: 17}}> Selecione o valor da doação *</p></strong>
                  <Form.Item name="valorDoacao" rules={[
                               { required: true, message: 'Campo obrigatório' },
                              ]} >
                  <Grid item lg={12} sm={12} xs={12} container justify="center" style={{paddingTop: 5, paddingLeft: 15}}>
                    <Form.Item name="valor" >

                      <Radio.Group>
                        <Grid container justify="center" style={{paddingLeft: 30}} >
                          <Grid justify="center" lg={3} sm={6} xs={12} style={{paddingRight: 150, paddingTop: 5}}>
                          <Radio value={10} onClick={(e: any) => setValorDoacao(e)} onChange={(e: any) => {setValorModifyValorDebito(e.target.value);  setFraseDoacao5(true)}} >
                            <strong> <p style={{fontSize: 25, color: '#50aebf'}}> R$ 10 </p></strong></Radio>
                          </Grid>

                          <Grid justify="center" lg={3} sm={6} xs={12} style={{paddingRight: 150, paddingTop: 5}}>
                          <Radio value={30} onClick={(e: any) => setValorDoacao(e)} onChange={(e: any) => {setValorModifyValorDebito(e.target.value);  setFraseDoacao6(true)}} >
                            <strong> <p style={{fontSize: 25, color: '#50aebf'}}> R$ 30 </p></strong></Radio>
                          </Grid>

                          <Grid justify="center" lg={3} sm={6} xs={12} style={{paddingRight: 150, paddingTop: 5}}>
                          <Radio value={60} onClick={(e: any) => setValorDoacao(e)} onChange={(e: any) => {setValorModifyValorDebito(e.target.value);  setFraseDoacao7(true)}} >
                            <strong> <p style={{fontSize: 25, color: '#50aebf'}}> R$ 60 </p></strong></Radio>
                          </Grid>

                          <Grid justify="center" lg={3} sm={6} xs={12} style={{paddingRight: 150, paddingTop: 5}}>
                          <Radio value={90}  onClick={(e: any) => setValorDoacao(e)} onChange={(e: any) => {setValorModifyValorDebito(e.target.value);  setFraseDoacao8(true)}} >
                            <strong> <p style={{fontSize: 25, color: '#50aebf'}}> R$ 90 </p></strong></Radio>
                          </Grid>

                        </Grid >

                        <InvisibleContent
                          visible={valorDoacao != true}
                        >
                          <Grid item container lg={12} sm={12} xs={12} justify="center" style={{paddingTop: 10, paddingRight: 100, paddingLeft: 30 }} >
                            <Radio value={''}  onChange={() => setValorDoacao(true)} style={{width:250}} >
                              <strong> <p style={{fontSize: 25, color: '#50aebf'}}> Outros valores </p></strong>
                            </Radio>
                          </Grid >
                        </InvisibleContent>
                      </Radio.Group>

                      <InvisibleContent
                        visible={valorDoacao === true}
                      >
                        <Grid container justify="center" >
                          <Grid item  justify="center" lg={6} sm={6} xs={12} style={{paddingTop: 10, paddingRight: 80, paddingLeft: 60 }}>
                            <Form.Item name="valor"
                              rules={[
                              {  pattern: /^[0-9]+$/, message: 'Por favor digite somente números!'},
                              ]}>
                              <Input
                                style={{height: 115, width: 250, fontSize: 18}}
                                type={"text"}
                                placeholder='Digite o valor da doação'
                                onChange={(e: any) => {
                                  formRef.setFieldsValue({
                                    valor: isReal(e.target.value),
                                  });
                                  setValorModifyValorDebito(e.target.value);
                                  setFraseDoacao9(true);
                                }}
                              />

                            </Form.Item>
                          </Grid>
                        </Grid>
                      </InvisibleContent>
                    </Form.Item>
                  </Grid>
                  </Form.Item>

                  {/* ------------------------ FORMA DE PAGAMENTO -------------------------------------------------- */}
                  <strong><p style={{paddingTop: 25, fontSize: 17}}> Selecione a forma de pagamento *</p></strong>


                  <Grid container justify="center" >
                    <Form.Item  name='doacao' rules={[
                               { required: true, message: 'Campo obrigatório' },
                              ]} >
                      <Radio.Group>
                        <Grid container item  lg={12} sm={12} xs={12} justify="center" style={{paddingLeft: 30}} >
                          <Grid item  lg={4} sm={4} xs={12}>
                              <Form.Item  name='boleto' valuePropName="checked">
                              <Radio name='boleto' value="boleto"
                                  style={{  width: 195, height: 50, borderRadius: 15, paddingTop: 6, paddingRight: 19, paddingBottom: 3.5, paddingLeft: 16,
                                  backgroundColor: '#50aebf'}}   onChange={() => setFormaContribBoleto(true)} onClick={(e: any) => {setFormaContribPix(e); setFormaContribCredito(e); setFormaContribDebito(e); setFormaContribEnergia(e) }}>
                                  <p style={{fontSize: 15, color: '#FFF', paddingTop: 6}}> Boleto </p>
                                </Radio>
                              </Form.Item>
                            </Grid>

                            <Grid item  lg={4} sm={4} xs={12}>
                              <Form.Item  name='pix' valuePropName="checked">
                                <Radio name='pix' value="pix"
                                  style={{  width: 195, height: 50, borderRadius: 15, paddingTop: 6, paddingRight: 19, paddingBottom: 3.5, paddingLeft: 16,
                                  backgroundColor: '#50aebf'}}  onChange={() => setFormaContribPix(true)} onClick={(e: any) => {setFormaContribCredito(e); setFormaContribBoleto(e); setFormaContribDebito(e); setFormaContribEnergia(e)}}>
                                    <p style={{fontSize: 15, color: '#FFF', paddingTop: 6}}> Pix </p>
                                </Radio>
                              </Form.Item>
                            </Grid>

                            <Grid item  lg={4} sm={4} xs={12}>
                              <Form.Item  name='cartao' valuePropName="checked">
                                <Radio  name='cartao' value="cartao"
                                  style={{  width: 195, height: 50, borderRadius: 15, paddingTop: 6, paddingRight: 19, paddingBottom: 3.5, paddingLeft: 16,
                                  backgroundColor: '#50aebf'}} onChange={() => setFormaContribCredito(true)} onClick={(e: any) => {setFormaContribPix(e); setFormaContribBoleto(e); setFormaContribDebito(e); setFormaContribEnergia(e) }}>
                                  <p style={{fontSize: 15, color: '#FFF', paddingTop: 6}}> Cartão de crédito </p>
                                </Radio>
                              </Form.Item>
                            </Grid>

                        </Grid >

                        <Grid container item  lg={12} sm={12} xs={12} justify="center" style={{paddingLeft: 30}} >
                          <Grid item  lg={4} sm={4} xs={12}>
                              <Form.Item  name='debito' valuePropName="checked">
                              <Radio name='debito' value="debito"
                                  style={{  width: 195, height: 50, borderRadius: 15, paddingTop: 6, paddingRight: 19, paddingBottom: 3.5, paddingLeft: 16,
                                  backgroundColor: '#50aebf'}}  onChange={() => setFormaContribDebito(true)} onClick={(e: any) => {setFormaContribPix(e); setFormaContribBoleto(e); setFormaContribCredito(e); setFormaContribEnergia(e) }}>
                                  <p style={{fontSize: 15, color: '#FFF', paddingTop: 6}}> Débito automático </p>
                                </Radio>
                              </Form.Item>
                            </Grid>

                            <Grid item  lg={4} sm={4} xs={12}>
                              <Form.Item  name='energia' valuePropName="checked">
                                <Radio  name='energia' value="energia"
                                  style={{  width: 195, height: 50, borderRadius: 15, paddingTop: 6, paddingRight: 19, paddingBottom: 3.5, paddingLeft: 16,
                                  backgroundColor: '#50aebf'}}  onChange={() => setFormaContribEnergia(true)} onClick={(e: any) => {setFormaContribPix(e); setFormaContribBoleto(e); setFormaContribDebito(e); setFormaContribCredito(e) }}>
                                  <p style={{fontSize: 15, color: '#FFF', paddingTop: 6}}> Conta de energia </p>
                                </Radio>
                              </Form.Item>
                            </Grid>

                        </Grid >
                      </Radio.Group>
                    </Form.Item>
                  </Grid>

                  {/* ------------------------ DOAÇÃO RECORRENTE ---------------------------------------------- */}

                  <Grid container justify="center" >
                    <InvisibleContent visible={formaContribDebito != true && formaContribEnergia != true} >
                      <Grid item container justify="space-around" sm={12} xs={12} style={{paddingTop: 25}}>
                        <Form.Item
                          name="recorrente"
                          valuePropName="checked"

                        >
                          <Checkbox
                            onChange={onChange}
                            style={{backgroundColor: '#20558a', width: 240, height: 60}} value="recorrente">
                            <strong style={{color: '#fff', paddingLeft: 30}}>Sócio-Protetor </strong>
                            <p style={{color: '#fff', fontSize: 12}}>(doação mensal e recorrente) </p>
                          </Checkbox>
                        </Form.Item>
                      </Grid>

                      <InvisibleContent visible={checked === true} >
                        <Grid item container justify="space-around" sm={12} xs={12}>
                          <p style={{fontSize: 13}}>Você pode cancelar quando quiser através da Central de Relacionamento com o Doador.</p>
                        </Grid>

                      </InvisibleContent>
                    </InvisibleContent>

                    {/* ------------------------ ACEITAR NORMAS E CONT. CADASTRO  ----------------------------- */}

                      <Grid container alignItems="center" justify="center">

                        <Grid container item lg={12} sm={12} xs={12}
                          style={{marginTop: 30, marginRight: 15, marginBottom: 35, marginLeft: 30}} justify="center"
                        >
                          <InvisibleContent visible={formaContribPix === true || formaContribBoleto === true || formaContribCredito === true || formaContribDebito === true || formaContribEnergia === true}>


                            <Grid item lg={6} sm={6} xs={12} justify="center">
                              <Form.Item name= "confirmacao" valuePropName='checked' rules={[{required: true, message: 'Campo obrigatório'}]}>
                                <Checkbox style={{backgroundColor: '#fff', color: '#000', borderColor: '#fff', width: 400, marginBottom: 55, marginRight: 50}} onChange={() => setConfirmacao(true)}>
                                <p>Estou de acordo em fornecer os dados acima com a finalidade de cumprir os trâmites necessários e receber informações sobre minha doação.</p>
                                </Checkbox>
                              </Form.Item>
                            </Grid>
                          </InvisibleContent>

                          <InvisibleContent visible={confirmacao != true}>

                            <Grid item lg={6} sm={12} xs={12} style={{}} justify="center" >
                              <Form
                                form={formPessoaRef}
                                scrollToFirstError={true}
                                onFinish={onSubmitTeste}
                              >
                                <ButtonComponent
                                  color="secondary"
                                  loading={loadingCreatePessoaTeste}
                                  type="submit"
                                  style={{height: 60, width: 150}}
                                >

                                  <NavigateNextOutlined style={{height: 50, width: 50}}/>
                                </ButtonComponent>
                              </Form>
                            </Grid>
                          </InvisibleContent>

                          <InvisibleContent visible={confirmacao === true && fraseDoacao0 === true && fraseDoacao1 === true && fraseDoacao2 === true && fraseDoacao3 === true && fraseDoacao4 === true} >
                            <InvisibleContent visible={fraseDoacao5 || true || fraseDoacao6 === true || fraseDoacao7 === true || fraseDoacao8 || true || fraseDoacao9 === true} >
                              <InvisibleContent visible={formaContribBoleto === true || formaContribPix === true || formaContribCredito === true || formaContribDebito === true || formaContribEnergia === true} >

                                <Grid item lg={6} sm={12} xs={12} style={{}} justify="center" >

                                  <ButtonComponent
                                    color="secondary"
                                    style={{height: 60, width: 250}}
                                    onClick={() => setLoading(true)}
                                  >
                                    Completar informações
                                    <NavigateNextOutlined style={{height: 50, width: 50}}/>
                                  </ButtonComponent>

                                </Grid>
                              </InvisibleContent>
                            </InvisibleContent>
                          </InvisibleContent>

                        </Grid>

                      </Grid>

                      {/* ------------------------ INDICADOR -------------------------------------------------- */}
                      <Grid container style={{paddingBottom: 10 }}>
                          <InvisibleContent visible={ loading === true}>
                          <div
                            style={{
                              background: '#fff',
                              border: `1px solid #999999  `,
                              borderRadius: 10,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              minHeight: '5',
                              maxHeight: '5',
                              padding: 10,
                              paddingTop: 10,
                              flex: 1,
                            }}
                          >
                            <Grid container>

                          <Grid container lg={12} sm={12}  xs={12} style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 5 }}>
                            <CircleNotifications style={{color: '#fff000'}}>

                            </CircleNotifications>
                            <p> Caso você veio por meio de uma indicação, cadastre o código do(a) <strong style={{color:'#3092cf'}}> INDICADOR(A) </strong>!</p>

                            </Grid>



                            <Grid item lg={12} sm={12}  xs={12} style={{ paddingTop: 5 }}>
                            <Form.Item
                                label="Código Sócio-Protetor"
                                name="indicador"
                                rules={[
                                  {  pattern: /^[0-9]+$/, message: 'Por favor digite somente números!'},

                                ]}
                              >
                                <Input
                                  type={"text"}

                                />
                              </Form.Item>

                            </Grid>
                            </Grid>
                          </div>


                          </InvisibleContent>
                          </Grid>


                      {/* ------------------------ COELBA -----------------------------*/}
                      <InvisibleContent visible={ loading === true && formaContribEnergia === true}>
                       <div
                            style={{
                              background: '#fff',
                              border: `1px solid #999999  `,
                              borderRadius: 10,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              minHeight: '5',
                              maxHeight: '5',
                              padding: 10,
                              paddingTop: 10,
                              flex: 1,
                            }}
                          >
                            <Grid container lg={12} sm={12}  xs={12} style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 5 }}>
                          <ReportGmailerrorred style={{color: '#CC0000'}}>

                          </ReportGmailerrorred>
                          <p> Somente pessoas que moram no estado da<strong style={{color:'#3092cf'}}> BAHIA </strong>que poderão realizar a doação por meio da contribuição conta de energia!</p>

                            </Grid>
                          </div>
                          </InvisibleContent>

                    {/* ------------------------ ENDEREÇO -------------------------------------------------- */}
                    <InvisibleContent visible={ loading === true}>
                      <>
                      <Grid container>
                          <p style={{marginTop: 15}}>Completar informações</p>
                        </Grid>

                        <Spin spinning={loadingCEP} tip="Buscando dados do CEP">
                          <Grid container>
                            <Grid container>
                              {/* { cep, uf, cidade } */}
                              <Grid item lg={4} xs={12}>
                                <Form.Item
                                  label="CEP"
                                  name="cep"
                                  rules={[
                                    { required: true, message: 'Campo obrigatório' }
                                  ]}
                                >
                                  <MaskedInput
                                    mask="11111-111"
                                    onChange={(e: any) => debounceCEP(e.target.value)}
                                  />
                                </Form.Item>
                              </Grid>
                            </Grid>

                            <Grid item lg={4} xs={12}>
                              <Form.Item
                                label="Estado"
                                name="uf"
                                rules={[{ required: true, message: "Campo obrigatório" },
                                {max: 2, message: "Somente sigla"}]}
                              >
                                <Input onInput={handleInput} onChange={(e: any) => {
                            //onChangeDocumento(e);
                            setUF(e.target.value);}} />
                              </Form.Item>
                            </Grid>

                            <Grid item lg={4} xs={12}>
                              <Form.Item
                                label="Cidade"
                                name="cidade"
                                rules={[{ required: true, message: "Campo obrigatório" }]}
                              >
                                <Input />
                              </Form.Item>
                            </Grid>

                              {/* { cep, uf, cidade } */}

                              {/* { endereço, numero } */}

                            <Grid item lg={4} xs={12}>
                              <Form.Item
                                label="Endereço"
                                name="descricao"
                                rules={[{ required: true, message: "Campo obrigatório" }]}
                              >
                                <Input/>
                              </Form.Item>
                            </Grid>
                              {/* { endereço, numero } */}

                              {/* { complemento, bairro } */}

                            <Grid item sm={4} xs={12}>
                              <Form.Item
                                label="Número"
                                name="numero"
                                rules={
                                  [
                                    {required: true,  message: "Campo obrigatório"}
                                  ]
                                }
                              >
                                <Input type={"number"} />
                              </Form.Item>
                            </Grid>

                            <Grid item sm={4} xs={12}>
                              <Form.Item
                                label="Complemento"
                                name="complemento"
                                rules={[
                                    { max: 45, message: 'Máximo de 45 caracteres' },
                                  ]}
                              >
                                <Input onInput={handleInput} />
                              </Form.Item>
                            </Grid>

                            <Grid item sm={4} xs={12}>
                              <Form.Item
                                label="Bairro"
                                name="bairro"
                                rules={[{ required: false }]}
                              >
                                <Input />
                              </Form.Item>
                            </Grid>
                          </Grid>
                        </Spin>

                        {/* ------------------------ REALIZAR DOAÇÃO PIX ----------------------------- */}

                        <InvisibleContent
                            visible={formaContribPix === true}
                          >
                            <Modal
                              title=""
                              size="medio"
                              openModal={openModalTermo}
                              controlModal={(value: boolean) =>
                                setOpenModalTermo(value)
                              }
                            >
                              <Termo/>
                            </Modal>

                            <Grid container>

                                    <Grid item lg={12} sm={12} xs={12}>
                                      <Form.Item name= "concede_compartilhamento_dados">
                                        <Checkbox value={checkedTermo2} style={{backgroundColor: '#fff', color: '#000', borderColor: '#fff', width: 1000,}} onChange={(e) => setCheckedTermo2(e.target.checked)}>
                                        <p>"Concedo o  compartilhamento dos dados pessoais aqui disponibilizados, para fins  participação nos benefícios do Clube do Bem".</p>
                                        </Checkbox>
                                       </Form.Item>
                                       </Grid>

                            </Grid>

                            <Grid container>
                              <Grid item lg={12} sm={12} xs={12}>
                                <Form.Item name= "termo2" valuePropName='checked' rules={[{required: true, message: 'Campo obrigatório'}]}>
                                  <Checkbox.Group>
                                    <Grid item lg={12} sm={12} xs={12}>
                                      <Form.Item name= "concede_compartilhamento_dados" valuePropName='checked'>
                                        <Checkbox style={{backgroundColor: '#fff', color: '#000', borderColor: '#fff', width: 1000,}} onChange={(e) => setCheckedTermo2(e.target.checked)}>
                                        <p>"Concedo o  compartilhamento dos dados pessoais aqui disponibilizados, para fins  participação nos benefícios do Clube do Bem".</p>
                                        </Checkbox>
                                       </Form.Item>
                                       </Grid>
                                  </Checkbox.Group>
                                </Form.Item>
                              </Grid>
                            </Grid>

                            <Grid item lg={6} sm={12} xs={12} style={{}} justify="center" >
                              <Form
                                form={formPessoaRef}
                                scrollToFirstError={true}
                                onFinish={onSubmitPix}
                                style={{ marginTop: 10, paddingLeft: 10, paddingRight: 10, marginBottom: 25}}
                              >
                                <ButtonComponent
                                  color="primary"
                                  loading={loadingCreatePessoaPix}
                                  type="submit"
                                    style={{height: 60, width: 250}}
                                  >
                                  Gerar pix
                                <NavigateNextOutlined />
                                </ButtonComponent>
                              </Form>
                            </Grid>
                          </InvisibleContent>

                      {/* ------------------------ REALIZAR DOAÇÃO BOLETO ----------------------------- */}
                      <InvisibleContent visible={formaContribBoleto === true}>

                      <Modal
                              title=""
                              size="medio"
                              openModal={openModalTermo}
                              controlModal={(value: boolean) =>
                                setOpenModalTermo(value)
                              }
                            >
                              <Termo/>
                            </Modal>

                            <Grid container>
                              <Grid item lg={12} sm={12} xs={12}>
                                <Form.Item name= "termo1" valuePropName='checked' rules={[{required: true, message: 'Campo obrigatório'}]}>
                                  <Checkbox.Group>
                                    <Grid item lg={12} sm={12} xs={12}>

                                      <Form.Item name= "leu_comunicado_privacidade" valuePropName='checked'>
                                        <Checkbox  style={{backgroundColor: '#fff', color: '#000', borderColor: '#fff', width: 1000,}}onChange={(e) => setCheckedTermo1(e.target.checked)}>
                                          <button type='button' onClick={() => setOpenModalTermo(true)}>"Li o <strong> Comunicado de Privacidade</strong>".</button>
                                        </Checkbox>

                                      </Form.Item>
                                    </Grid>
                                  </Checkbox.Group>
                                </Form.Item>
                              </Grid>
                            </Grid>

                           <Grid container>

                                    <Grid item lg={12} sm={12} xs={12}>
                                      <Form.Item name= "concede_compartilhamento_dados">
                                        <Checkbox value={checkedTermo2} style={{backgroundColor: '#fff', color: '#000', borderColor: '#fff', width: 1000,}} onChange={(e) => setCheckedTermo2(e.target.checked)}>
                                        <p>"Concedo o  compartilhamento dos dados pessoais aqui disponibilizados, para fins  participação nos benefícios do Clube do Bem".</p>
                                        </Checkbox>
                                       </Form.Item>
                                       </Grid>

                            </Grid>

                        <Grid container justify="center" >
                          <Form
                            form={formPessoaRef}
                            scrollToFirstError={true}
                            onFinish={onSubmitBoleto}>
                            <Grid
                              item
                              lg={12}
                              sm={12}
                              xs={12}
                              style={{ marginTop: 10, paddingLeft: 10, paddingRight: 10, marginBottom: 25}}
                            >
                            <ButtonComponent
                              color="primary"
                              loading={loadingCreatePessoaBoleto}
                              type="submit"
                            >
                              Realizar doação
                            </ButtonComponent>
                          </Grid>
                          </Form>
                        </Grid>
                        </InvisibleContent>

                      {/* ------------------ CADASTRAR CARTÃO DE CRÉDITO ------------------------- */}
                        <InvisibleContent visible={formaContribCredito === true}>

                        <Grid container>
                            <div
                              style={{
                                background: '#fff',
                                border: `1px solid #999999  `,
                                borderRadius: 10,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: 'auto',
                                maxHeight: 'auto',
                                padding: 20,
                                paddingTop: 20,
                                flex: 1,
                              }}
                            >
                              <Grid container lg={12} sm={12}  xs={12} style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 40 }}>
                              <Grid container lg={5} sm={12}  xs={12} style={{ paddingTop: 10 }} >
                                <div
                                  style={{
                                    background: '#999999',
                                    border: `1px solid #999999  `,
                                    borderRadius: 10,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: 250,
                                    height: 200,
                                    //padding: 20,
                                    //paddingTop: 20,
                                    flex: 1,
                                  }}
                                >
                                  <Grid container >
                                    <Grid container style={{paddingTop: 20}}>
                                      <Grid item lg={1} sm={1}  xs={1} style={{paddingLeft: 10}}></Grid>

                                      <Grid item lg={3} sm={3}  xs={3} style={{paddingLeft: 10}}>
                                        <img src={Chip}
                                          width="50"
                                          height="50">
                                        </img>
                                      </Grid>
                                    </Grid>

                                    <Grid container >
                                      <Grid item lg={12} sm={12}  xs={12} style={{ paddingRight: 20}}>
                                        <Form.Item
                                          name="number"
                                        >
                                          <Input disabled placeholder=' **** **** **** ****'
                                            style={{backgroundColor: '#999', borderColor: '#999', color: '#FFF', fontSize: 20}}/>
                                        </Form.Item>
                                      </Grid>
                                    </Grid>

                                    <Grid container >
                                      <Grid item lg={3} sm={3}  xs={3}>
                                        <Form.Item
                                          name="first_name"
                                        >
                                          <Input disabled placeholder='TITULAR'
                                            style={{backgroundColor: '#999', borderColor: '#999', color: '#FFF', fontSize: 10}}/>
                                        </Form.Item>
                                      </Grid>

                                      <Grid item lg={4} sm={4}  xs={4} >
                                        <Form.Item
                                          name="last_name"
                                        >
                                          <Input disabled placeholder='DO CARTÃO '
                                            style={{backgroundColor: '#999', borderColor: '#999', color: '#FFF', fontSize: 10}}/>
                                        </Form.Item>
                                      </Grid>

                                      <Grid item lg={1} sm={1}  xs={1} >
                                        <Form.Item
                                          name="month"
                                        >
                                          <Input disabled placeholder='**'
                                            style={{backgroundColor: '#999', borderColor: '#999', color: '#FFF', width: 30, fontSize: 10}}/>
                                        </Form.Item>
                                      </Grid>

                                      <Grid item lg={2} sm={2}  xs={2}>
                                        <Form.Item
                                          name="year"
                                        >
                                          <Input disabled placeholder='****'
                                            style={{backgroundColor: '#999', borderColor: '#999', color: '#FFF', fontSize: 10}}/>
                                        </Form.Item>
                                      </Grid>

                                      <Grid item lg={2} sm={2}  xs={2}>
                                        <Form.Item
                                          name="verification_value"
                                        >
                                          <Input disabled placeholder='***'
                                            style={{backgroundColor: '#999', borderColor: '#999', color: '#FFF', fontSize: 10}}/>
                                        </Form.Item>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </div>

                                <Grid container>
                                  < img src="https://s3-sa-east-1.amazonaws.com/storage.pupui.com.br/9CA0F40E971643D1B7C8DE46BBC18396/assets/cc-icons.e8f4c6b4db3cc0869fa93ad535acbfe7.png" alt="Visa, Master, Diners. Amex" />
                                    <a id="iugu-btn" href="http://iugu.com"><img src="https://s3-sa-east-1.amazonaws.com/storage.pupui.com.br/9CA0F40E971643D1B7C8DE46BBC18396/assets/payments-by-iugu.1df7caaf6958f1b5774579fa807b5e7f.png" alt="Pagamentos por Iugu"  /></a>

                                </Grid>
                              </Grid>

                              <Grid container lg={7} sm={12}  xs={12}>
                                <Grid container >
                                  <Grid item lg={12} sm={12}  xs={12} style={{ paddingRight: 10, paddingLeft: 10 }}>
                                    <Form.Item
                                      label="Número do cartão *"
                                      name="number"
                                      rules={[
                                          { required: true, message: 'Campo obrigatório' },


                                      ]}
                                    >
                                      <Input
                                        maxLength={16}
                                      />
                                    </Form.Item>
                                  </Grid>
                                </Grid>

                                  <Grid container>
                                    <Grid item lg={6} sm={12} xs={12}>
                                      <Form.Item
                                        label='Nome impresso cartão *'
                                        name="first_name"
                                        rules={[
                                          { required: true, message: 'Campo obrigatório' },


                                        ]}
                                        style={{ paddingLeft: 10, paddingRight: 10 }}
                                      >
                                        <Input />
                                        </Form.Item>
                                    </Grid>

                                    <Grid item lg={6} sm={12} xs={12}>
                                      <Form.Item
                                        label='Sobrenome impresso cartão *'
                                        name="last_name"
                                        rules={[
                                          { required: true, message: 'Campo obrigatório' },

                                        ]}
                                        style={{ paddingLeft: 10, paddingRight: 10 }}
                                      >
                                      <Input />
                                      </Form.Item>
                                    </Grid>
                                  </Grid>

                                  <Grid container>
                                    <Grid item lg={4} sm={4} xs={4}>
                                      <Form.Item
                                        label='Mês *'
                                        name="month"
                                        rules={[
                                          { required: true, message: 'Campo obrigatório' },
                                          {  pattern: /^[0-9]+$/, message: 'Por favor digite somente números!'},

                                        ]}
                                        style={{ paddingLeft: 10, paddingRight: 10 }}
                                      >
                                        <Input maxLength={2} minLength={2} />
                                        </Form.Item>
                                    </Grid>

                                    <Grid item lg={4} sm={4} xs={4}>
                                      <Form.Item
                                        label='Ano *'
                                        name="year"
                                        rules={[
                                          { required: true, message: 'Campo obrigatório' },
                                          {  pattern: /^[0-9]+$/, message: 'Por favor digite somente números!'},

                                        ]}
                                        style={{ paddingLeft: 10, paddingRight: 10 }}
                                      >
                                      <Input maxLength={4} minLength={4} />
                                      </Form.Item>
                                    </Grid>

                                    <Grid item lg={4} sm={4} xs={4}>
                                      <Form.Item
                                        label='CVV *'
                                        name="verification_value"
                                        rules={[
                                          { required: true, message: 'Campo obrigatório' },

                                        ]}
                                        style={{ paddingLeft: 10, paddingRight: 10 }}
                                      >
                                      <Input />
                                      </Form.Item>
                                    </Grid>

                                  </Grid>

                                </Grid>
                              </Grid>
                              </div>
                              </Grid>
                     {/* ------------------------ REALIZAR DOAÇÃO CARTÃO DE CRÉDITO ----------------------------- */}
                     <Modal
                              title=""
                              size="medio"
                              openModal={openModalTermo}
                              controlModal={(value: boolean) =>
                                setOpenModalTermo(value)
                              }
                            >
                              <Termo/>
                            </Modal>

                            <Grid container>

                                    <Grid item lg={12} sm={12} xs={12}>
                                      <Form.Item name= "concede_compartilhamento_dados">
                                        <Checkbox value={checkedTermo2} style={{backgroundColor: '#fff', color: '#000', borderColor: '#fff', width: 1000,}} onChange={(e) => setCheckedTermo2(e.target.checked)}>
                                        <p>"Concedo o  compartilhamento dos dados pessoais aqui disponibilizados, para fins  participação nos benefícios do Clube do Bem".</p>
                                        </Checkbox>
                                       </Form.Item>
                                       </Grid>

                            </Grid>

                            <Grid container>
                              <Grid item lg={12} sm={12} xs={12}>
                                <Form.Item name= "termo2" valuePropName='checked' rules={[{required: true, message: 'Campo obrigatório'}]}>
                                  <Checkbox.Group>
                                    <Grid item lg={12} sm={12} xs={12}>
                                      <Form.Item name= "concede_compartilhamento_dados" valuePropName='checked'>
                                        <Checkbox style={{backgroundColor: '#fff', color: '#000', borderColor: '#fff', width: 1000,}} onChange={(e) => setCheckedTermo2(e.target.checked)}>
                                        <p>"Concedo o  compartilhamento dos dados pessoais aqui disponibilizados, para fins  participação nos benefícios do Clube do Bem".</p>
                                        </Checkbox>
                                       </Form.Item>
                                       </Grid>
                                  </Checkbox.Group>
                                </Form.Item>
                              </Grid>
                            </Grid>


                          <Form
                            form={formPessoaRef}
                            scrollToFirstError={true}
                            onFinish={onSubmitCredito}
                          >
                            <Grid container justify="center" >
                              <Grid
                                item
                                lg={12}
                                sm={12}
                                xs={12}
                                style={{ marginTop: 10, paddingLeft: 10, paddingRight: 10, marginBottom: 25}}
                              >
                                <ButtonComponent
                                  color="primary"
                                  loading={loadingCreatePessoaCredito}
                                  fullWidth
                                  type="submit"
                                >
                                  Realizar doação
                                </ButtonComponent>
                              </Grid>
                            </Grid>
                          </Form>
                        </InvisibleContent>

                      {/* ------------------------ CADASTRAR DÉBITO AUTOMÁTICO ----------------------------- */}
                      {/* ------------------------ CARTÃO DÉBITO AUTOMÁTICO ----------------------------- */}
                        <InvisibleContent visible={formaContribDebito === true}>
                          <Grid container style={{paddingTop: 40 }}>
                            <div
                              style={{
                                background: '#fff',
                                border: `1px solid #999999  `,
                                borderRadius: 10,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: 'auto',
                                maxHeight: 'auto',
                                padding: 20,
                                paddingTop: 20,
                                flex: 1,
                              }}
                            >
                              <Grid container lg={12} sm={12}  xs={12} style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 5 }}>

                                <Grid container lg={5} sm={8}  xs={12} style={{ paddingTop: 10 }} >
                                  <InvisibleContent
                                    visible={convenioSelecionada
                                    .toLocaleUpperCase()
                                    .includes('SANTANDER') ||
                                    convenioSelecionada
                                    .toLocaleUpperCase()
                                    .includes('CAIXA') }
                                  >
                                    <div
                                      style={{
                                        background: '#999999',
                                        border: `1px solid #999999  `,
                                        borderRadius: 10,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: 250,
                                        height: 200,
                                        //padding: 20,
                                        //paddingTop: 20,
                                        flex: 1,
                                      }}
                                    >
                                      <Grid container >
                                        <Grid container style={{paddingTop: 20}}>
                                          <Grid item lg={1} sm={1}  xs={1} style={{paddingLeft: 10}}></Grid>
                                          <Grid item lg={3} sm={3}  xs={3} style={{paddingLeft: 10}}>
                                            <img src={Chip}
                                              width="50"
                                              height="50">
                                            </img>
                                          </Grid>

                                          <Grid item lg={8} sm={8}  xs={8} style={{ paddingRight: 5}}>
                                            <Form.Item
                                              name="convenio"
                                            >
                                              <Input disabled placeholder=' **************** '
                                              style={{backgroundColor: '#999', borderColor: '#999', color: '#FFF'}}/>
                                            </Form.Item>
                                          </Grid>
                                        </Grid>

                                        <p style={{fontSize: 30, color: '#fff', paddingLeft: 20}}> ..... ......  ........ ........</p>

                                        <Grid container >
                                          <Grid item lg={2} sm={2}  xs={2}>
                                            <Form.Item
                                              name="numero_agencia"
                                            >
                                              <Input disabled placeholder=' **** '
                                              style={{backgroundColor: '#999', borderColor: '#999', color: '#FFF'}}/>
                                            </Form.Item>
                                          </Grid>

                                          <Grid item lg={1} sm={1}  xs={1} >
                                            <Form.Item
                                              name="digito_agencia"
                                            >
                                              <Input disabled placeholder=' * '
                                              style={{backgroundColor: '#999', borderColor: '#999', color: '#FFF'}}/>
                                            </Form.Item>
                                          </Grid>

                                          <Grid item lg={2} sm={2}  xs={2} >
                                            <Form.Item
                                              name="operacao"
                                            >
                                              <Input disabled placeholder=' *** '
                                              style={{backgroundColor: '#999', borderColor: '#999', color: '#FFF'}}/>
                                            </Form.Item>
                                          </Grid>

                                          <Grid item lg={5} sm={5}  xs={5}>
                                            <Form.Item
                                              name="numero_conta"
                                            >
                                              <Input disabled placeholder=' ******** '
                                              style={{backgroundColor: '#999', borderColor: '#999', color: '#FFF'}}/>
                                            </Form.Item>
                                          </Grid>

                                          <Grid item lg={2} sm={2}  xs={2}>
                                            <Form.Item
                                              name="digito_conta"
                                            >
                                              <Input disabled placeholder=' * '
                                              style={{backgroundColor: '#999', borderColor: '#999', color: '#FFF'}}/>
                                            </Form.Item>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </InvisibleContent>


                                  <InvisibleContent
                                    visible={!convenioSelecionada
                                    .toLocaleUpperCase()
                                    .includes('SANTANDER') && !convenioSelecionada
                                    .toLocaleUpperCase()
                                    .includes('CAIXA')}
                                  >
                                    <div
                                      style={{
                                        background: '#999999',
                                        border: `1px solid #999999  `,
                                        borderRadius: 10,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: 250,
                                        height: 200,
                                        //padding: 20,
                                        //paddingTop: 20,
                                        flex: 1,
                                      }}
                                    >
                                      <Grid container >
                                        <Grid container style={{paddingTop: 20}}>
                                          <Grid item lg={1} sm={1}  xs={1} style={{paddingLeft: 10}}></Grid>
                                          <Grid item lg={3} sm={3}  xs={3} style={{paddingLeft: 10}}>
                                            <img src={Chip}
                                              width="50"
                                              height="50">
                                            </img>
                                          </Grid>

                                          <Grid item lg={8} sm={8}  xs={8} style={{ paddingRight: 5}}>
                                            <Form.Item
                                              name="convenio"
                                            >
                                              <Input disabled placeholder=' **************** '
                                              style={{backgroundColor: '#999', borderColor: '#999', color: '#FFF'}}/>
                                            </Form.Item>
                                          </Grid>
                                        </Grid>

                                          <p style={{fontSize: 30, color: '#fff', paddingLeft: 20}}> ..... ......  ........ ........</p>


                                        <Grid container >
                                          <Grid item lg={3} sm={3}  xs={3}>
                                            <Form.Item
                                              name="numero_agencia"
                                            >
                                              <Input disabled placeholder=' **** '
                                              style={{backgroundColor: '#999', borderColor: '#999', color: '#FFF'}}/>
                                            </Form.Item>
                                          </Grid>

                                          <Grid item lg={2} sm={2}  xs={2} >
                                            <Form.Item
                                              name="digito_agencia"
                                            >
                                              <Input disabled placeholder=' * '
                                              style={{backgroundColor: '#999', borderColor: '#999', color: '#FFF'}}/>
                                            </Form.Item>
                                          </Grid>

                                          <Grid item lg={5} sm={5}  xs={5}>
                                            <Form.Item
                                              name="numero_conta"
                                            >
                                              <Input disabled placeholder=' ******** '
                                              style={{backgroundColor: '#999', borderColor: '#999', color: '#FFF'}}/>
                                            </Form.Item>
                                          </Grid>

                                          <Grid item lg={2} sm={2}  xs={2}>
                                            <Form.Item
                                              name="digito_conta"
                                            >
                                              <Input disabled placeholder=' * '
                                              style={{backgroundColor: '#999', borderColor: '#999', color: '#FFF'}}/>
                                            </Form.Item>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </div>
                                 </InvisibleContent>

                              </Grid>
                              {/* ------------------------ FORMULÁRIO DÉBITO AUTOMÁTICO ----------------------------- */}

                              <Grid container lg={7} sm={12}  xs={12}>
                                <Grid container >
                                  <Grid item lg={8} sm={8}  xs={8} style={{ paddingRight: 10, paddingLeft: 10 }}>
                                    <Form.Item
                                      name="convenio"
                                      label="Convênio"
                                      rules={[
                                        {
                                          required: true,
                                        },
                                      ]}
                                    >
                                      <SelectAntd
                                        onSelect={(e: any) => filterCheckTipoConvenio(e)}
                                      >
                                        {convenio.map(item => (
                                          <SelectAntd.Option key={item.id} value={item.descricao}>
                                            {item.descricao}
                                          </SelectAntd.Option>
                                        ))}
                                      </SelectAntd>
                                    </Form.Item>
                                  </Grid>

                                  <Grid item lg={4} sm={4} xs={4} style={{ paddingRight: 10, paddingLeft: 10 }}>
                                    <Form.Item
                                      name="vencimento"
                                      label="Dia vencimento"
                                      rules={[
                                        {
                                          required: true,
                                        },
                                      ]}
                                    >
                                      <SelectAntd>
                                      {data.map(item => (
                                          <SelectAntd.Option key={item.id} value={item.id}>
                                            {item.id}
                                          </SelectAntd.Option>
                                        ))}
                                      </SelectAntd>
                                    </Form.Item>
                                  </Grid>
                                </Grid>

                                <Grid container >
                                  <Grid item lg={8} sm={8}  xs={8} style={{ paddingRight: 10, paddingLeft: 10 }}>
                                    <Form.Item
                                      label="Número agência *"
                                      name="numero_agencia"
                                      rules={[
                                        { required: true, message: 'Campo obrigatório' },
                                        {  pattern: /^[0-9]+$/, message: 'Por favor digite somente números!'},
                                        { max: 4, message: 'Máximo de  4 carácter' },

                                      ]}
                                    >
                                      <Input />

                                    </Form.Item>
                                  </Grid>

                                  <Grid item lg={4} sm={4}  xs={4} style={{ paddingRight: 10, paddingLeft: 10 }}>
                                    <Form.Item
                                      label="Dígito agência *"
                                      name="digito_agencia"
                                      rules={[
                                        { required: false, message: 'Campo obrigatório' },
                                        {  pattern: /^[a-zA-Z0-9]+$/, message: "Somente números ou letras"},
                                        { max: 1, message: 'Máximo de  1 carácter' },
                                        ]}
                                    >
                                      <Input />

                                    </Form.Item>
                                  </Grid>
                                </Grid>

                                <Grid container >
                                  <Grid item lg={8} sm={8}  xs={8} style={{ paddingRight: 10, paddingLeft: 10 }}>
                                    <Form.Item
                                      label="Número conta *"
                                      name="numero_conta"
                                      rules={[
                                        { required: true, message: 'Campo obrigatório' },
                                        {  pattern: /^[0-9]+$/, message: 'Por favor digite somente números!'},

                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Grid>

                                  <Grid item lg={4} sm={4}  xs={4} style={{ paddingRight: 10, paddingLeft: 10 }}>
                                    <Form.Item
                                      label="Dígito conta *"
                                      name="digito_conta"
                                      rules={[
                                        { required: false, message: 'Campo obrigatório' },
                                        {  pattern: /^[a-zA-Z0-9]+$/, message: "Somente números ou letras"},

                                      ]}
                                    >
                                      <Input />

                                    </Form.Item>
                                  </Grid>
                                </Grid>

                                <InvisibleContent
                                  visible={convenioSelecionada
                                  .toLocaleUpperCase()
                                  .includes('SANTANDER') }
                                >

                                  <Grid item sm={6} xs={12}>
                                    <Form.Item
                                      name="operacao"
                                      label="Operação"
                                      rules={[
                                        {
                                          required: true,
                                        },
                                      ]}
                                    >
                                    < SelectAntd>
                                        {operacaoSantander.map(item => (
                                          <SelectAntd.Option
                                            key={item.codigo_operacao}
                                            value={item.codigo_operacao}
                                          >
                                            {item.codigo_operacao + ' - ' + item.descricao}
                                          </SelectAntd.Option>
                                        ))}
                                      </SelectAntd>
                                    </Form.Item>
                                  </Grid>
                                </InvisibleContent>

                                <InvisibleContent
                                  visible={convenioSelecionada
                                  .includes('CAIXA') }
                                >

                                  <Grid item sm={6} xs={12}>
                                    <Form.Item
                                      name="operacao"
                                      label="Operação"
                                      rules={[
                                        {
                                          required: true,
                                        },
                                      ]}
                                    >
                                    < SelectAntd>
                                        {operacaoCaixa.map(item => (
                                          <SelectAntd.Option
                                            key={item.codigo_operacao}
                                            value={item.codigo_operacao}
                                          >
                                            {item.codigo_operacao + ' - ' + item.descricao}
                                          </SelectAntd.Option>
                                        ))}
                                      </SelectAntd>
                                    </Form.Item>
                                  </Grid>
                                </InvisibleContent>

                              </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        {/* --------------- REALIZAR DOAÇÃO DÉBITO AUTOMÁTICO --------------------------- */}

                        <Modal
                              title=""
                              size="medio"
                              openModal={openModalTermo}
                              controlModal={(value: boolean) =>
                                setOpenModalTermo(value)
                              }
                            >
                              <Termo/>
                            </Modal>

                            <Grid container>

                                    <Grid item lg={12} sm={12} xs={12}>
                                      <Form.Item name= "concede_compartilhamento_dados">
                                        <Checkbox value={checkedTermo2} style={{backgroundColor: '#fff', color: '#000', borderColor: '#fff', width: 1000,}} onChange={(e) => setCheckedTermo2(e.target.checked)}>
                                        <p>"Concedo o  compartilhamento dos dados pessoais aqui disponibilizados, para fins  participação nos benefícios do Clube do Bem".</p>
                                        </Checkbox>
                                       </Form.Item>
                                       </Grid>

                            </Grid>

                            <Grid container>
                              <Grid item lg={12} sm={12} xs={12}>
                                <Form.Item name= "termo2" valuePropName='checked' rules={[{required: true, message: 'Campo obrigatório'}]}>
                                  <Checkbox.Group>
                                    <Grid item lg={12} sm={12} xs={12}>
                                      <Form.Item name= "concede_compartilhamento_dados" valuePropName='checked'>
                                        <Checkbox style={{backgroundColor: '#fff', color: '#000', borderColor: '#fff', width: 1000,}} onChange={(e) => setCheckedTermo2(e.target.checked)}>
                                        <p>"Concedo o  compartilhamento dos dados pessoais aqui disponibilizados, para fins  participação nos benefícios do Clube do Bem".</p>
                                        </Checkbox>
                                       </Form.Item>
                                       </Grid>
                                  </Checkbox.Group>
                                </Form.Item>
                              </Grid>
                            </Grid>

                          <Grid container justify="center" >
                            <Form
                              form={formPessoaRef}
                              scrollToFirstError={true}
                              onFinish={onSubmitDebito}>
                              <Grid
                                item
                                lg={12}
                                sm={12}
                                xs={12}
                                style={{ marginTop: 10, paddingLeft: 10, paddingRight: 10, marginBottom: 25}}
                              >
                              <ButtonComponent
                                color="primary"
                                loading={loadingCreatePessoaDebito}
                                type="submit"
                              >
                                Realizar doação
                              </ButtonComponent>
                            </Grid>
                            </Form>
                          </Grid>
                      </InvisibleContent>


                       {/* --------------- REALIZAR DOAÇÃO CONTA ENERGIA --------------------------- */}

                      <InvisibleContent visible={formaContribEnergia === true}>
                        <Grid container style={{paddingTop: 40 }}>
                          <div
                            style={{
                              background: '#fff',
                              border: `1px solid #999999  `,
                              borderRadius: 10,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              minHeight: 'auto',
                              maxHeight: 'auto',
                              padding: 20,
                              paddingTop: 20,
                              flex: 1,
                            }}
                          >
                            <Grid container lg={12} sm={12}  xs={12} style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 5 }}>

                              <Grid container lg={5} sm={8}  xs={12} style={{ paddingTop: 10 }} >
                                <img src={Energia}
                                    >
                                </img>

                              </Grid>

                              <Grid container lg={7} sm={12}  xs={12}>
                                <Grid container >
                                  <Grid item lg={8} sm={8}  xs={8} style={{ paddingRight: 10, paddingLeft: 10 }}>
                                    <Form.Item
                                      label="Número conta *"
                                      name="numero_conta_energia"
                                      rules={[
                                        { required: true, message: 'Campo obrigatório' },
                                        {  pattern: /^[0-9]+$/, message: 'Por favor digite somente números!'},

                                      ]}
                                    >
                                      <Input disabled={uf !== 'BA'} maxLength={12}/>
                                    </Form.Item>
                                  </Grid>

                                  <Grid item lg={4} sm={4}  xs={4} style={{ paddingRight: 10, paddingLeft: 10 }}>
                                  <Form.Item
                                      name="recorrencia"
                                      label="Recorrência"
                                      rules={[
                                        {
                                          required: true,
                                        },
                                      ]}
                                    >
                                      <SelectAntd disabled={uf !== 'BA'}>
                                        {recorrencias.map(item => (
                                          <SelectAntd.Option key={item.descricao} value={item.descricao}>
                                            {item.id + ' - ' + item.descricao}
                                        </SelectAntd.Option>
                                        ))}
                                      </SelectAntd>
                                    </Form.Item>
                                  </Grid>
                                </Grid>

                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        {/* --------------- REALIZAR DOAÇÃO CONTA ENERGIA --------------------------- */}

                        <Modal
                              title=""
                              size="medio"
                              openModal={openModalTermo}
                              controlModal={(value: boolean) =>
                                setOpenModalTermo(value)
                              }
                            >
                              <Termo/>
                            </Modal>

                            <Grid container>
                              <Grid item lg={12} sm={12} xs={12}>
                                <Form.Item name= "termo1" valuePropName='checked' rules={[{required: true, message: 'Campo obrigatório'}]}>
                                  <Checkbox.Group>
                                    <Grid item lg={12} sm={12} xs={12}>

                                      <Form.Item name= "leu_comunicado_privacidade" valuePropName='checked'>
                                        <Checkbox  style={{backgroundColor: '#fff', color: '#000', borderColor: '#fff', width: 1000,}}onChange={(e) => setCheckedTermo1(e.target.checked)}>
                                          <button type='button' onClick={() => setOpenModalTermo(true)}>"Li o <strong> Comunicado de Privacidade</strong>".</button>
                                        </Checkbox>

                                      </Form.Item>
                                    </Grid>
                                  </Checkbox.Group>
                                </Form.Item>
                              </Grid>
                            </Grid>

                            <Grid container>

                                    <Grid item lg={12} sm={12} xs={12}>
                                      <Form.Item name= "concede_compartilhamento_dados">
                                        <Checkbox value={checkedTermo2} style={{backgroundColor: '#fff', color: '#000', borderColor: '#fff', width: 1000,}} onChange={(e) => setCheckedTermo2(e.target.checked)}>
                                        <p>"Concedo o  compartilhamento dos dados pessoais aqui disponibilizados, para fins  participação nos benefícios do Clube do Bem".</p>
                                        </Checkbox>
                                       </Form.Item>
                                       </Grid>

                            </Grid>

                          <Grid container justify="center" >
                            <Form
                              form={formPessoaRef}
                              scrollToFirstError={true}
                              onFinish={onSubmitEnergia}>
                              <Grid
                                item
                                lg={12}
                                sm={12}
                                xs={12}
                                style={{ marginTop: 10, paddingLeft: 10, paddingRight: 10, marginBottom: 25}}
                              >
                              <ButtonComponent
                                color="primary"
                                disabled={uf !== 'BA'}
                                loading={loadingCreatePessoaEnergia}
                                type="submit"
                              >
                                Realizar doação
                              </ButtonComponent>
                            </Grid>
                            </Form>
                          </Grid>
                      </InvisibleContent>

                      </>
                    </InvisibleContent>

                    { /* ------------------------ DADOS OCULTOS -------------------------------------------------- */}
                  </Grid>


            </Form>
        </Grid>

        <Footer />
        <Grid item lg={12} sm={12} xs={12} style={{paddingTop: 20}}> </Grid>
        <Footer2 />

      </Grid>
    </>
  );
};

export default Principal;
